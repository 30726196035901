import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../api.service';
import {
  AccountResponse,
  CreateEditCsvSourceRequest,
  CreateEditGoogleSourceRequest,
  SourceModel
} from '../../models/source.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Papa from 'papaparse';

declare let $: any;

@Component({
  selector: 'app-create-edit-source',
  templateUrl: './create-edit-source.component.html',
  styleUrls: ['./create-edit-source.component.scss']
})
export class CreateEditSourceComponent implements OnInit {
  constructor(public route: ActivatedRoute, public router: Router, public apiService: ApiService) { }

  public mode: 'create' | 'edit' = 'create';
  public source: SourceModel;
  public csvData: string[][];

  public loading: boolean;
  public sourceForm: FormGroup;
  public sourceTypes = [
    { value: 'google', viewValue: 'Google Sheets' },
    { value: 'csv', viewValue: 'CSV' }
  ];
  public account: AccountResponse;
  public teamDrives = [];
  public spreadsheetResults = [];
  public sheetResults = [];
  public showSpreadsheetResults = false;
  public selectedSpreadsheet: {
    id: string;
    name: string;
  };
  public sheet_search_term;

  public valid: { [key: string]: boolean | string } = {};
  public showHeader = false;

  ngOnInit(): void {
    this.showHeader = window.parent === window.self;

    this.valid = {
      name: true,
      source_type: true,
      csv_data: true,
      search_team_drives: true,
      team_drives_select: true,
      spreadsheet_search: true,
      sheet_select: true
    };

    // set up the basic form
    this.sourceForm = new FormGroup({
      name: new FormControl(null, [Validators.required, Validators.minLength(3)]),
      source_type: new FormControl(null, [Validators.required]),

      // CSV
      csv_data: new FormControl(null),

      // GOOGLE
      search_team_drives: new FormControl(false),
      team_drives_select: new FormControl(null),
      spreadsheet_search: new FormControl(null),
      sheet_select: new FormControl(this.source?.tab_id)
    });

    this.route.params.subscribe((params) => {
      if (!!params['id']) {
        this.loading = true;
        this.mode = 'edit';

        this.apiService.getSourceById(params['id']).subscribe((source) => {
          console.log('[ngOnInit params Observer] source', source);
          this.source = source;
          this.sourceForm.controls['name'].setValue(source.name);
          this.sourceForm.controls['source_type'].setValue(this.source?.source_type?.toLowerCase());
          this.sourceTypeChange();
          this.source_type.disable();

          if (this.source.source_type == 'GOOGLE') {
            console.log('source driveId', this.safeGetSourceData('driveId'));

            // set the default drive ID after we retrieve the list
            if (!!this.safeGetSourceData('driveId')) {
              this.sourceForm.controls['search_team_drives'].setValue(this.safeGetSourceData('driveId') != '');
              this.fetchTeamDrives().then(() => {
                this.sourceForm.controls['team_drives_select'].setValue(this.safeGetSourceData('driveId'));
                this.sourceForm.controls['spreadsheet_search'].enable();
              });
            }

            this.selectedSpreadsheet = {
              id: this.source.external_id,
              name: this.source.data['spreadsheet_name']
            };
            this.sourceForm.controls['spreadsheet_search'].setValue(this.safeGetSourceData('spreadsheet_name'));

            // set the default sheet/tab name after we retrieve the list
            this.getGoogleSpreadsheetSheets(this.source.external_id).then(() => {
              console.log('source tab_id', this.source.tab_id);
              this.sourceForm.controls['sheet_select'].setValue(this.source.tab_id);
            });
          }
        });
      }
    });

    this.route.queryParams.subscribe((params) => {
      if (params['source_type'] == 'google') {
        this.source_type?.setValue('google');
        this.sourceTypeChange();
      }
    });

    // fetch the account
    this.apiService.getAccountDetails().subscribe((account) => {
      this.account = account;
      this.loading = false; // display spinner until we have the auth providers back
      console.log('account', account);
    });

    // close the search results on ESC
    window.document.addEventListener('keyup', (e) => {
      if (e.code.toLowerCase() == 'escape') {
        this.showSpreadsheetResults = false;
      }
    });
  }

  public sourceTypeChange() {
    if (this.source_type.getRawValue() == 'google') {
      this.csv_data.removeValidators(Validators.required);
      this.csv_data.updateValueAndValidity();

      this.spreadsheet_search.addValidators(Validators.required);
      this.spreadsheet_search.addValidators(Validators.minLength(3));
      this.spreadsheet_search.updateValueAndValidity();

      this.sheet_select.addValidators(Validators.required);
      this.sheet_select.updateValueAndValidity();
    } else if (this.source_type.getRawValue() == 'csv') {
      this.csv_data.addValidators(Validators.required);
      this.csv_data.updateValueAndValidity();

      this.spreadsheet_search.removeValidators(Validators.required);
      this.spreadsheet_search.removeValidators(Validators.minLength(3));
      this.spreadsheet_search.updateValueAndValidity();

      this.sheet_select.removeValidators(Validators.required);
      this.sheet_select.updateValueAndValidity();

      this.team_drives_select.removeValidators(Validators.required);
      this.team_drives_select.updateValueAndValidity();
    }
  }

  public get name() {
    return this.sourceForm?.get('name');
  }

  public get source_type() {
    return this.sourceForm?.get('source_type');
  }

  public get csv_data() {
    return this.sourceForm?.get('csv_data');
  }

  public get search_team_drives() {
    return this.sourceForm?.get('search_team_drives');
  }

  public get team_drives_select() {
    return this.sourceForm?.get('team_drives_select');
  }

  public get spreadsheet_search() {
    return this.sourceForm?.get('spreadsheet_search');
  }

  public get sheet_select() {
    return this.sourceForm?.get('sheet_select');
  }

  public get isTypeCsv(): boolean {
    return this.source_type?.getRawValue() == 'csv';
  }

  public get isTypeGoogle(): boolean {
    return this.source_type?.getRawValue() == 'google';
  }

  public get hasGoogleAuthProvider(): boolean {
    if (!!this.account?.auth_providers?.length) {
      return this.account.auth_providers.filter((ap) => ap.provider == 'GOOGLE' && ap.status == 'ACTIVE').length > 0;
    }
    return false;
  }

  public get isSearchTeamDrive(): boolean {
    return !!this.search_team_drives.getRawValue();
  }

  public safeGetSourceData(field: string): string {
    if (!!this.source?.data) {
      if (!!this.source.data[field]) {
        return this.source.data[field];
      }
    }
    return '';
  }

  public fetchTeamDrives() {
    this.team_drives_select.setValue(null); // need to do that so that validation catches it
    this.sheet_select.setValue(null);
    this.spreadsheet_search.setValue(null);
    this.selectedSpreadsheet = undefined;

    if (this.isSearchTeamDrive) {
      this.spreadsheet_search.disable(); // disabled until a selection is made
      this.team_drives_select.addValidators(Validators.required);
      return new Promise((resolve) => {
        this.apiService.getGoogleTeamDrives().subscribe((response) => {
          this.teamDrives = response.drives;
          resolve(response);
        });
      });
    } else {
      this.team_drives_select.removeValidators(Validators.required);
      this.spreadsheet_search.enable();
      this.teamDrives = [];
      return Promise.resolve();
    }
  }

  public teamDriveSelection() {
    if (!!this.team_drives_select.getRawValue()) {
      this.valid['team_drives_select'] = true;
      this.spreadsheet_search.setValue(null);
      this.selectedSpreadsheet = undefined;
      this.spreadsheet_search.enable();
    }
  }

  public showGoogleAuthModal() {
    $('#googleAuthInfoModal').modal('show');
  }

  public removeGoogleAuthModal() {
    $('#googleAuthInfoModal').modal('hide');
    this.router.navigate(['/home']);
  }

  public googleAuthLink() {
    // fetch the user's session ID
    this.apiService.getSession().subscribe((response) => {
      // construct the redirect URL
      // const url = `${this.apiService.apiEndpoint}/oauth/google`;
      const params = new URLSearchParams({
        sid: response.session_id,
        aid: this.account.id,
        redirect: `${this.apiService.apiEndpoint}/oauth/google`
      });
      let url = `${this.apiService.apiEndpoint}/v1/redirect?${params.toString()}`;
      window.open(url);
    });
  }

  public executeSearch(event) {
    if (event.type == 'click' || event.code?.toLowerCase() == 'enter') {
      event.preventDefault();
      this.sheet_select.setValue(null);
      if (this.spreadsheet_search.valid) {
        this.valid['spreadsheet_search'] = true;
        this.spreadsheetSearch();
      } else {
        this.valid['spreadsheet_search'] = Object.keys(this.spreadsheet_search.errors).shift();
      }
    }
  }

  public spreadsheetSearch() {
    const search_term = this.spreadsheet_search.getRawValue();
    this.apiService
      .doGoogleSpreadsheetSearch(search_term, this.team_drives_select.getRawValue() || undefined)
      .subscribe((response) => {
        this.spreadsheetResults = response.spreadsheets;
        if (response.spreadsheets.length == 0) {
          this.apiService.showNotification('top', 'right', 'No spreadsheets found', 'warning');
        } else {
          this.showSpreadsheetResults = true;
        }
      });
  }

  public selectSpreadsheet(sheet) {
    this.selectedSpreadsheet = sheet;
    this.sheet_search_term = sheet.name;
    this.showSpreadsheetResults = false;
    this.valid['sheet_search_term'] = true;
    // get the sheets for that spreadsheet and populate the dropdown
    this.getGoogleSpreadsheetSheets(sheet.id);
  }

  public getGoogleSpreadsheetSheets(sheet_id) {
    return new Promise((resolve) => {
      this.apiService.getGoogleSpreadsheetSheets(sheet_id).subscribe((response) => {
        this.sheetResults = response.sheets;
        this.sheet_select.enable();
        resolve(response);
      });
    });
  }

  private validationCheck() {
    for (const ctrl in this.sourceForm.controls) {
      console.log(`Validation check: ${ctrl} = ${this.sourceForm.get(ctrl).valid}`, this.sourceForm.get(ctrl).errors);
      this.valid[ctrl] = this.sourceForm.get(ctrl).valid;
    }

    if (!this.selectedSpreadsheet && this.source_type.getRawValue() == 'google') {
      this.valid['spreadsheet_search'] = 'required';
      this.spreadsheet_search.setErrors({
        required: true
      });
    }
  }

  public saveGoogleSource() {
    this.validationCheck();

    if (this.sourceForm.valid) {
      this.loading = true;
      // Create the source
      const request: CreateEditGoogleSourceRequest = {
        name: this.name.getRawValue(),
        source_type: 'GOOGLE',
        spreadsheetId: this.selectedSpreadsheet.id,
        spreadsheetName: this.selectedSpreadsheet.name,
        sheetName: this.sheet_select.getRawValue()
      };

      if (this.search_team_drives.getRawValue()) {
        request.driveId = this.team_drives_select.getRawValue();
      }

      if (!!this.source?.id) {
        request.id = this.source.id;
        return this.apiService.updateSource(request).subscribe(() => {
          this.loading = false;
          this.router.navigate(['/home']);
          return true;
        });
      } else {
        return this.apiService.createSource(request).subscribe({
          next: (response) => {
            this.source = response;
            this.loading = false;
            this.router.navigate(['/home']);
            return true;
          },
          error: (e) => {
            console.log(e);
            this.apiService.showNotification('top', 'right', 'Error Creating Source! ' + e?.error?.message, 'warning');
            this.loading = false;
          }
        });
      }
    } else {
      return false;
    }
  }

  public saveCsvSource() {
    this.validationCheck();

    if (this.sourceForm.valid) {
      this.loading = true;

      const request: CreateEditCsvSourceRequest = {
        name: this.name.getRawValue(),
        source_type: 'CSV'
      };

      if (!!this.source?.id) {
        request.id = this.source.id;
        this.apiService.updateSource(request).subscribe((_response) => {
          if (this.csv_data.getRawValue().length > 0) {
            // update the CSV data as well
            this.saveCsvData();
          } else {
            this.loading = false;
            this.router.navigate(['/home']);
          }
        });
      } else {
        this.apiService.createSource(request).subscribe({
          next: (response) => {
            this.source = response;
            this.saveCsvData();
          },
          error: (e) => {
            console.log(e);
            this.loading = false;
            this.apiService.showNotification('top', 'right', 'Error Creating Source! ' + e?.error?.message, 'warning');
          }
        });
      }
    } else {
      this.loading = false;
    }
  }

  private saveCsvData() {
    try {
      const rows = Papa.parse(this.csv_data.getRawValue(), {
        skipEmptyLines: true
      }).data;
      const headers = rows.shift();

      this.apiService.saveSourceData(this.source.id, { headers, rows }).subscribe(() => {
        this.loading = false;
        this.router.navigate(['/home']);
      });
    } catch (e) {
      console.log(e);
      this.apiService.showNotification('top', 'right', 'Error Updating Source!', 'danger');
    }
  }
}
