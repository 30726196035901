<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12" *ngIf="loading">
        <div class="spinner__loading">
          <div>
            <mat-progress-spinner diameter="60" mode="indeterminate"> </mat-progress-spinner>
          </div>
        </div>
      </div>
      <div class="col-md-12" *ngIf="!loading">
        <p *ngIf="sourcesList?.length == 0">
          No sources yet. <a [routerLink]="'/source/create'">Create a new Source</a>.
        </p>
        <div class="table-responsive" *ngIf="sourcesList?.length">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th>Source Name</th>
                <th>Source Type</th>
                <th>Last VLOOKUP Date</th>
              </tr>
              <tr *ngFor="let source of sourcesList">
                <td>
                  <a [routerLink]="'/source/' + source.id">{{ source.name }}</a>
                </td>
                <td>{{ source.source_type }}</td>
                <td *ngIf="source.last_vlookup_date">
                  {{ source.last_vlookup_date | date : 'MMM d, y' }}
                </td>
                <td *ngIf="!source.last_vlookup_date">Never used</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
