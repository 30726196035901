import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './layouts/home/home.component';
import { SourceListComponent } from './layouts/source-list/source-list.component';
import { CreateEditSourceComponent } from './layouts/create-edit-source/create-edit-source.component';
import { SourceDetailsComponent } from './layouts/source-details/source-details.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'sources',
    component: SourceListComponent
  },
  {
    path: 'source',
    children: [
      {
        path: 'edit/:id',
        component: CreateEditSourceComponent
      },
      {
        path: 'create',
        component: CreateEditSourceComponent
      },
      {
        path: ':id',
        component: SourceDetailsComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: false
    })
  ],
  exports: []
})
export class AppRoutingModule {}
