import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  AccountResponse,
  SourceData,
  SourceModel,
  SourceUpdateResponse,
  GoogleTeamDriveResponse,
  GoogleSpreadsheetSearchResponse,
  GoogleSpreadsheetSheetsResponse,
  CreateEditCsvSourceRequest,
  CreateEditGoogleSourceRequest,
  SessionResponse
} from './models/source.model';
import { environment } from '../environments/environment';

declare let $: any;

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public apiEndpoint: string;
  private enableMock = false;
  private sessionId: string;

  constructor(private http: HttpClient) {
    this.enableMock = environment.enable_mock_api;
    this.apiEndpoint = environment.api_endpoint;

    this.sessionId = localStorage.getItem('vlookup_session_id');
  }

  getSession() {
    return this.http.get<SessionResponse>(
      `${this.apiEndpoint}/v1/session`,
      this.buildHeaderOptions()
    );
  }

  getAllSources() {
    if (this.enableMock) {
      return this.http.get<SourceModel[]>('assets/data/list-sources.json');
    } else {
      return this.http.get<SourceModel[]>(`${this.apiEndpoint}/v1/sources`, this.buildHeaderOptions());
    }
  }

  getSourceById(id: any) {
    if (this.enableMock) {
      return this.http.get<SourceModel>('assets/data/get-source.json');
    } else {
      return this.http.get<SourceModel>(`${this.apiEndpoint}/v1/source/${id}`, this.buildHeaderOptions());
    }
  }

  getSourceData(id: any) {
    if (this.enableMock) {
      return this.http.get<SourceData>('assets/data/get-source-data.json');
    } else {
      return this.http.get<SourceData>(`${this.apiEndpoint}/v1/source/${id}/data`, this.buildHeaderOptions());
    }
  }

  deleteSource(id: any) {
    if (this.enableMock) {
      return this.http.get<SourceUpdateResponse>('assets/data/save-source-data-res.json');
    } else {
      return this.http.delete<SourceUpdateResponse>(`${this.apiEndpoint}/v1/source/${id}`, this.buildHeaderOptions());
    }
  }

  saveSourceData(id: any, data: any) {
    if (this.enableMock) {
      return this.http.get<SourceUpdateResponse>('assets/data/save-source-data-res.json');
    } else {
      return this.http.put<SourceUpdateResponse>(
        `${this.apiEndpoint}/v1/source/${id}/data`,
        data,
        this.buildHeaderOptions()
      );
    }
  }

  createSource(request: CreateEditCsvSourceRequest | CreateEditGoogleSourceRequest) {
    if (this.enableMock) {
      return this.http.get<SourceModel>('assets/data/save-source-data-res.json');
    } else {
      return this.http.post<SourceModel>(`${this.apiEndpoint}/v1/source`, request, this.buildHeaderOptions());
    }
  }

  updateSource(request: CreateEditCsvSourceRequest | CreateEditGoogleSourceRequest) {
    if (this.enableMock) {
      return this.http.get<SourceModel>('assets/data/save-source-data-res.json');
    } else {
      const source_id = request.id;
      delete request.id;

      return this.http.put<SourceModel>(
        `${this.apiEndpoint}/v1/source/${source_id}`,
        request,
        this.buildHeaderOptions()
      );
    }
  }

  getAccountDetails() {
    if (this.enableMock) {
      return this.http.get<AccountResponse>('assets/data/get-account.json');
    } else {
      return this.http.get<AccountResponse>(`${this.apiEndpoint}/v1/account`, this.buildHeaderOptions());
    }
  }

  getGoogleTeamDrives() {
    return this.http.get<GoogleTeamDriveResponse>(
      `${this.apiEndpoint}/v1/ext/google?mode=drives`,
      this.buildHeaderOptions()
    );
  }

  doGoogleSpreadsheetSearch(fragment: string, driveId?: string | undefined) {
    const params = new URLSearchParams({
      mode: 'spreadsheets',
      ssq: fragment
    });
    if (!!driveId) {
      params.append('driveId', driveId);
    }
    return this.http.get<GoogleSpreadsheetSearchResponse>(
      `${this.apiEndpoint}/v1/ext/google?${params.toString()}`,
      this.buildHeaderOptions()
    );
  }

  getGoogleSpreadsheetSheets(spreadsheetId: string) {
    return this.http.get<GoogleSpreadsheetSheetsResponse>(
      `${this.apiEndpoint}/v1/ext/google?mode=sheets&ssid=${spreadsheetId}`,
      this.buildHeaderOptions()
    );
  }

  private buildHeaderOptions() {
    const headers = new HttpHeaders();

    return {
      headers: headers,
      withCredentials: true
    };
  }

  showNotification(from, align, messageText, messageType) {
    const type = ['', 'info', 'success', 'warning', 'danger'];

    const color = Math.floor(Math.random() * 4 + 1);

    $.notify(
      {
        icon: 'notifications',
        message: messageText
      },
      {
        type: messageType ? messageType : type[color],
        timer: 2000,
        placement: {
          from: from,
          align: align
        },
        template: `<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">
<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>
<i class="material-icons" data-notify="icon">notifications</i> 
<span data-notify="title">{1}</span> 
<span data-notify="message">{2}</span>
<div class="progress" data-notify="progressbar">
<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>
</div>
<a href="{3}" target="{4}" data-notify="url"></a>
</div>`
        // template:
        //   '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
        //   '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        //   '<i class="material-icons" data-notify="icon">notifications</i> ' +
        //   '<span data-notify="title">{1}</span> ' +
        //   '<span data-notify="message">{2}</span>' +
        //   '<div class="progress" data-notify="progressbar">' +
        //   '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        //   '</div>' +
        //   '<a href="{3}" target="{4}" data-notify="url"></a>' +
        //   '</div>'
      }
    );
  }
}
