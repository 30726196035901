// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  debug: true,
  production: false,
  enable_mock_api: false,
  api_endpoint: 'https://dev.vlookup.cambiumapps.com',
  checkout_url: 'https://dev.vlookup.cambiumapps.com/v1/checkout',
  manage_account_url: 'https://dev.vlookup.cambiumapps.com/v1/account/manage'
};
