import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SourceModel } from '../../models/source.model';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-source-list',
  templateUrl: './source-list.component.html',
  styleUrls: ['./source-list.component.scss']
})
export class SourceListComponent implements OnInit, OnChanges {
  sourcesList: SourceModel[];
  loading: boolean;

  constructor(private route: ActivatedRoute, private apiService: ApiService) {}

  ngOnInit(): void {
    this.loadAllSources();
  }

  private loadAllSources() {
    this.loading = true;
    this.sourcesList = null;
    this.apiService.getAllSources().subscribe((data) => {
      this.sourcesList = data;
      this.loading = false;
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this.loadAllSources();
  }
}
