<div class="main-content">
  <div class="container-fluid">
    <div class="row brand-header" *ngIf="showHeader">
      <div class="col-md-12">
        <img src="/assets/img/cambium-vlookup-logo.png" alt="Cambium Apps VLOOKUP for HubSpot" />
      </div>
    </div>

    <div class="row">
      <span class="pt-1"><a [routerLink]="'/home'" fragment="source-list" class="pl-2">Sources</a></span>
      <span class="pt-1">&nbsp;/&nbsp;</span>
      <span class="pt-1" style="font-weight: bold">{{ sourceHeaderData?.name }} </span>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <td>
                      <h2 class="pt-3"><b>Source Details</b></h2>
                    </td>
                    <td>
                      <span class="text-info link-cursor float-right" (click)="editSource()">
                        <b>Edit Source</b>
                      </span>
                    </td>
                  </tr>
                </thead>
              </table>

              <hr />

              <table class="table">
                <tbody>
                  <tr>
                    <td><b>Name</b></td>
                    <td>{{ sourceHeaderData?.name }}</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td><b>Type</b></td>
                    <td>{{ sourceHeaderData?.source_type }}</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr *ngIf="isGoogleSource">
                    <td><b>Spreadsheet</b></td>
                    <td>
                      {{ googleSpreadsheetName }}
                      <a
                        href="https://docs.google.com/spreadsheets/d/{{ sourceHeaderData.external_id }}"
                        target="_blank"
                        ><i class="material-icons">open_in_new</i></a
                      >
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr *ngIf="isGoogleSource">
                    <td><b>Sheet</b></td>
                    <td>{{ sourceHeaderData.tab_id }}</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr *ngIf="sourceIsReady">
                    <td><b>Headers</b></td>
                    <td [innerHTML]="source_headers | safeHtml"></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr *ngIf="isCsvSource && sourceIsReady">
                    <td><b>Number of rows</b></td>
                    <td>{{ sourceHeaderData.row_count }}</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td><b>Last VLOOKUP Date</b></td>
                    <td *ngIf="sourceHeaderData?.last_vlookup_date">
                      {{ sourceHeaderData?.last_vlookup_date | date : 'MMM d, y' }}
                    </td>
                    <td *ngIf="!sourceHeaderData?.last_vlookup_date">Never used in a VLOOKUP</td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr />

            <section *ngIf="isCsvSource && sourceIsReady">
              <div class="row">
                <div class="col-md-6">
                  <h2>
                    Source Data&nbsp;<span style="color: #0000008a; font-size: 75%; font-weight: normal"
                      >(preview)</span
                    >
                  </h2>
                </div>
                <div class="col-md-6">
                  <span class="text-info link-cursor float-right" (click)="download()">
                    <b>Download Source Data</b>
                  </span>
                </div>
              </div>

              <div class="table-responsive">
                <table mat-table [dataSource]="dataSourceWithPageSize" class="w-100 table table-bordered">
                  <ng-container [matColumnDef]="column" *ngFor="let column of sourceData?.headers; let i = index">
                    <th mat-header-cell *matHeaderCellDef>
                      <b>{{ column }}</b>
                    </th>
                    <td mat-cell *matCellDef="let emp">{{ emp[i] }}</td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="sourceData?.headers"></tr>
                  <tr mat-row *matRowDef="let emprow; columns: sourceData?.headers"></tr>
                </table>
              </div>
            </section>

            <div class="row">
              <div class="col-md-6">
                <button mat-raised-button type="submit" class="btn btn-info" [routerLink]="'/home'">Back</button>
              </div>
              <div class="col-md-6">
                <button
                  mat-raised-button
                  type="submit"
                  class="btn btn-danger float-right"
                  data-toggle="modal"
                  data-target="#deleteConfirmationModal"
                >
                  Delete Source
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal modal-angular fade"
  id="deleteConfirmationModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="confirmModelLabel"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="margin-top"><span></span>Confirm Deletion</h4>
        <div class="separator"></div>
        <div>
          Are you sure you want to delete the Source {{ sourceHeaderData?.name }}
          <br />
          <br />

          Note: No Hubspot data will be lost, but any workflows using this source will no longer work.
        </div>

        <div class="row">
          <div class="col-md-6">
            <button mat-raised-button type="submit" class="btn btn-info" data-dismiss="modal" aria-label="Close">
              Cancel
            </button>
          </div>
          <div class="col-md-6">
            <button
              mat-raised-button
              type="submit"
              class="btn btn-danger float-right"
              data-dismiss="modal"
              aria-label="Close"
              (click)="deleteSource()"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
