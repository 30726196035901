import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../api.service';
import { Subscription } from 'rxjs';
import { SourceData, SourceModel } from '../../models/source.model';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-source-details',
  templateUrl: './source-details.component.html',
  styleUrls: ['./source-details.component.scss']
})
export class SourceDetailsComponent implements OnInit, OnDestroy {
  private routeSub: Subscription;
  private sourceId: any;
  public showHeader = false;
  sourceHeaderData: SourceModel;
  sourceData: SourceData;

  dataSourceWithPageSize: MatTableDataSource<any>;

  @ViewChild('paginatorPageSize') paginatorPageSize: MatPaginator;

  pageSizes = [5, 10, 25, 100];
  pageSize = 5;

  constructor(private route: ActivatedRoute, public router: Router, private apiService: ApiService) {}

  ngOnInit(): void {
    this.showHeader = window.parent === window.self;
  }

  ngAfterViewInit() {
    this.routeSub = this.route.params.subscribe((params) => {
      this.sourceId = params['id'];
      this.apiService.getSourceById(this.sourceId).subscribe((sourceHeader) => {
        this.sourceHeaderData = sourceHeader;

        if (this.isCsvSource) {
          this.apiService.getSourceData(this.sourceId).subscribe((sourceData) => {
            this.sourceData = sourceData;

            this.dataSourceWithPageSize = new MatTableDataSource(this.sourceData.rows);
            // this.dataSourceWithPageSize.paginator = this.paginatorPageSize;
          });
        }
      });
    });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

  public get isGoogleSource(): boolean {
    return this.sourceHeaderData?.source_type == 'GOOGLE';
  }

  public get isCsvSource(): boolean {
    return this.sourceHeaderData?.source_type == 'CSV';
  }

  public get sourceIsReady(): boolean {
    return this.sourceHeaderData?.status == 'READY';
  }

  public get source_headers(): string {
    if (this.sourceHeaderData?.headers?.length) {
      const headers = this.sourceHeaderData.headers.slice();
      const key = headers.shift();
      headers.unshift(`<b>${key}</b>`);
      return headers.join(', ');
    }
    return '';
  }

  public get googleSpreadsheetName(): string {
    if (this.isGoogleSource) {
      if (!!this.sourceHeaderData?.data) {
        return this.sourceHeaderData?.data['spreadsheet_name'];
      } else {
        return '[unknown]';
      }
    }
    return '';
  }

  deleteSource() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.apiService.deleteSource(this.sourceId).subscribe((response) => {
      // Handle Response
      this.apiService.showNotification('top', 'right', 'Deleted Source Successfully!', 'success');
      setTimeout(() => {
        this.router.navigate(['/home']);
      }, 2000);
    });
  }

  editSource() {
    this.router.navigateByUrl('/source/edit/' + this.sourceId, {
      state: {
        header: this.sourceHeaderData,
        body: this.sourceData
      }
    });
  }

  download() {
    const url = `${environment.api_endpoint}/v1/source/${this.sourceId}/download`;
    window.open(url, 'download');
  }
}
