<div class="main-content">
  <div class="container-fluid">
    <div class="row brand-header" *ngIf="showHeader">
      <div class="col-md-12">
        <img src="/assets/img/cambium-vlookup-logo.png" alt="Cambium Apps VLOOKUP for HubSpot" />
      </div>
    </div>
    <div class="card" *ngIf="accountDetails">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <td>
                      <h2 class="pt-3"><b>Account Information</b></h2>
                    </td>
                    <td colspan="4">
                      <a
                        mat-raised-button
                        type="submit"
                        class="btn btn-success float-right text-white"
                        target="_blank"
                        [href]="showManageAccount ? manageUrl : checkoutUrl"
                      >
                        {{ showManageAccount ? 'Manage My Account' : 'Activate My Account' }}
                      </a>
                      &nbsp;&nbsp;
                      <a
                        *ngIf="isCancelled"
                        mat-raised-button
                        type="submit"
                        class="btn btn-success float-right text-white"
                        target="_blank"
                        [href]="checkoutUrl"
                      >
                        Re-Activate Account
                      </a>
                    </td>
                  </tr>
                  <tr class="border-top-1">
                    <td colspan="4"></td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><b>Account Name</b></td>
                    <td>{{ accountDetails?.name }} (HubSpot Portal ID: {{ accountDetails?.id }})</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td><b>Account Status</b></td>
                    <td>
                      <ng-template
                        [ngIf]="accountDetails?.status == 'TRIAL' && accountDetails?.trial_expiration_date"
                        [ngIfElse]="statusAlt"
                      >
                        <span>
                          On <b>trial</b> until {{ accountDetails.trial_expiration_date | date : 'MMMM dd, yyyy' }}
                        </span>
                      </ng-template>
                      <ng-template #statusAlt>
                        <span>{{ accountDetails?.status | titlecase }}</span>
                      </ng-template>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card" *ngIf="accountDetails?.isActive && !cancelledOrExpired" id="source-list">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <td>
                      <h2 class="pt-3"><b>VLOOKUP Sources</b></h2>
                    </td>
                    <td>
                      <h3 class="text-info link-cursor float-right button-text" [routerLink]="'/source/create'">
                        + Create New Source
                      </h3>
                    </td>
                  </tr>
                  <tr class="border-top-1">
                    <td colspan="4"></td>
                  </tr>
                </thead>
              </table>
              <app-source-list></app-source-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal modal-angular fade"
  id="sessionExpiredModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="confirmModelLabel"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="notice warning">
          <h3>Session Expired</h3>

          <p>
            Your session has expired. Please sign back in by closing this window, then clicking the 'Manage' button
            again.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
