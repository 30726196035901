import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  handleError(error) {
    if (environment.debug) {
      console.error('An error occurred:', error?.message);
      console.error(error);
    }
  }
}
