import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ApiService } from '../../api.service';
import { AccountResponse } from '../../models/source.model';
import { ActivatedRoute } from '@angular/router';

declare let $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnChanges, OnDestroy {
  paymentHandler: any = null;
  accountDetails: AccountResponse;
  expired = false;
  checkoutUrl: any;
  manageUrl: any;
  showManageAccount = false;
  cancelledOrExpired: boolean;
  isCancelled: boolean;
  showHeader = false;

  constructor(private route: ActivatedRoute, private apiService: ApiService) {
  }

  ngOnDestroy(): void {
    this.paymentHandler = null;
    this.accountDetails = null;
    this.expired = false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this.initialize();
  }

  ngOnInit(): void {
    this.showHeader = window.parent === window.self;
    this.initialize();
  }

  private initialize() {
    this.apiService.getAccountDetails().subscribe({
      next: (account) => {
        this.accountDetails = account;
        this.checkoutUrl = account.checkout_url;
        this.manageUrl = account.manage_url;
        
        if (this.accountDetails?.trial_expiration_date) {
          this.expired = new Date(this.accountDetails?.trial_expiration_date) < new Date();
          this.cancelledOrExpired = ['cancelled', 'expired'].includes(this.accountDetails?.status?.toLowerCase());
        }
        this.showManageAccount = ['active', 'cancelled'].includes(this.accountDetails?.status?.toLowerCase());
        this.isCancelled = ['cancelled'].includes(this.accountDetails?.status?.toLowerCase());
      },
      error: (err: Error) => {
        console.error('Error retrieving account details', err);
        $('#sessionExpiredModal').modal('show');
      }
    });
    
    this.route.queryParams.subscribe((params) => {
      if (!!params['error'] && params['error'] == 'checkout') {
        // TODO: this will be set on a checkout error
      }
    });
  }

  reload() {
    console.log(parent.location.href);
    parent.location.href = parent.location.href.replace(/general-settings.*/i, 'home');
  }
}
