<div class="main-content">
  <div class="container-fluid">
    <div class="row brand-header" *ngIf="showHeader">
      <div class="col-md-12">
        <img src="/assets/img/cambium-vlookup-logo.png" alt="Cambium Apps VLOOKUP for HubSpot" />
      </div>
    </div>

    <div class="row">
      <span class="pt-1"><a [routerLink]="'/home'" fragment="source-list" class="pl-2">Sources</a></span>
      <span class="pt-1">&nbsp;/&nbsp;</span>
      <ng-container *ngIf="mode == 'create'">
        <span class="pt-1" style="font-weight: bold">New Source</span>
      </ng-container>
      <ng-container *ngIf="mode == 'edit'">
        <span class="pt-1"
          ><a [routerLink]="'/source/' + source?.id" class="pl-2">{{ source?.name }}</a></span
        >
        <span class="pt-1">&nbsp;/&nbsp;</span>
        <span class="pt-1" style="font-weight: bold">Edit</span>
      </ng-container>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12" *ngIf="loading">
            <div class="spinner__loading">
              <div>
                <mat-progress-spinner diameter="60" mode="indeterminate"> </mat-progress-spinner>
              </div>
            </div>
          </div>

          <div class="col-md-12" *ngIf="!loading">
            <form [formGroup]="sourceForm">
              <div class="row">
                <div class="col-md-6">
                  <mat-label *ngIf="!valid['name']" class="error">Source name is required.</mat-label>
                  <mat-form-field appearance="fill">
                    <mat-label>Source name</mat-label>
                    <input matInput formControlName="name" placeholder="Name" type="text" required />
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <mat-form-field appearance="fill">
                    <mat-label>Source type</mat-label>
                    <mat-select formControlName="source_type" required (selectionChange)="sourceTypeChange()">
                      <mat-option>Select...</mat-option>
                      <mat-option *ngFor="let st of sourceTypes" [value]="st.value">
                        {{ st.viewValue }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <!-- CSV FORM -->
              <div *ngIf="isTypeCsv">
                <div class="row">
                  <div class="col-md-12">
                    <h2 class="text-black">CSV Source Data</h2>
                    <div class="notice info" *ngIf="mode == 'create'">
                      <p>Enter the data into the field below.</p>
                      <p>
                        The field accepts CSV or TSV formatted data. The easiest approach is to COPY and PASTE data from
                        Excel or Google Sheets right into the field below.
                      </p>
                    </div>
                    <div class="notice info" *ngIf="mode == 'edit'">
                      <p>Enter the data into the field below to <b>REPLACE</b> the current data for this source.</p>
                      <p>
                        The field accepts CSV or TSV formatted data. The easiest approach is to COPY and PASTE data from
                        Excel or Google Sheets right into the field below.
                      </p>
                    </div>
                    <mat-label *ngIf="!valid['csv_data']" class="error"> Please add data below. </mat-label>
                    <mat-form-field appearance="fill">
                      <textarea matInput formControlName="csv_data" rows="10"></textarea>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <button mat-raised-button type="submit" class="btn btn-info" [routerLink]="'/home'">Cancel</button>
                  </div>
                  <div class="col-md-6">
                    <button
                      mat-raised-button
                      type="submit"
                      class="btn btn-success float-right"
                      (click)="saveCsvSource()"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <!-- END CSV FORM -->

              <!-- GOOGLE FORM-->
              <ng-container *ngIf="isTypeGoogle">
                <ng-container *ngIf="!hasGoogleAuthProvider">
                  <div class="row">
                    <!-- google auth prompt -->
                    <button
                      mat-button
                      (click)="showGoogleAuthModal()"
                      type="button"
                      style="border: none; background-color: inherit"
                    >
                      <img src="/assets/img/google-signin.png" height="48" width="199" />
                    </button>
                  </div>
                </ng-container>
                <ng-container *ngIf="hasGoogleAuthProvider">
                  <!-- Google Auth exists -->
                  <div class="row">
                    <div class="col-md-6">
                      <mat-checkbox (change)="fetchTeamDrives()" formControlName="search_team_drives">
                        <span style="color: #212529">Search Shared Drive?</span>
                      </mat-checkbox>
                    </div>
                  </div>
                  <div class="row" *ngIf="isSearchTeamDrive">
                    <div class="col-md-6">
                      <!-- list team drives -->
                      <mat-label *ngIf="!valid['team_drives_select']" class="error">
                        Please select a Shared Drive.
                      </mat-label>
                      <mat-form-field appearance="fill">
                        <mat-label>Select a Shared Drive</mat-label>
                        <mat-select formControlName="team_drives_select" (selectionChange)="teamDriveSelection()">
                          <mat-option>Select...</mat-option>
                          <mat-option *ngFor="let drive of teamDrives" [value]="drive.id">
                            {{ drive.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row search">
                    <div class="col-md-6">
                      <!-- file search -->
                      <mat-label class="error" *ngIf="valid['spreadsheet_search'] == 'required'">
                        Please select a spreadsheet.
                      </mat-label>
                      <mat-label class="error" *ngIf="valid['spreadsheet_search'] == 'minlength'">
                        Please enter at least 3 characters.
                      </mat-label>
                      <mat-form-field appearance="fill">
                        <mat-label>Spreadsheet Search</mat-label>
                        <input
                          matInput
                          formControlName="spreadsheet_search"
                          autocomplete="off"
                          placeholder="Spreadsheet name"
                          type="text"
                          [value]="sheet_search_term"
                          (keypress)="executeSearch($event)"
                        />
                        <mat-icon class="search" (click)="executeSearch($event)">search</mat-icon>
                      </mat-form-field>
                      <mat-icon class="open_in_new" aria-label="Open Sheet" *ngIf="!!selectedSpreadsheet"
                        ><a href="https://docs.google.com/spreadsheets/d/{{ selectedSpreadsheet.id }}" target="_blank"
                          >open_in_new</a
                        ></mat-icon
                      >

                      <div *ngIf="showSpreadsheetResults" id="spreadsheetSearchResults">
                        <ul>
                          <li *ngFor="let ss of spreadsheetResults" (click)="selectSpreadsheet(ss)">
                            <div class="sheet-name">
                              <span class="image">
                                <img src="/assets/img/icon-google-sheets.png" width="16" height="16" />
                              </span>
                              {{ ss.name }}
                            </div>
                            <div class="folder-name">
                              <span class="image">
                                <img src="/assets/img/icon-folder-small.png" width="12" height="12" />
                              </span>
                              {{ ss.folder }}
                            </div>
                          </li>
                        </ul>
                      </div>

                      <!-- end file search -->
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <!-- Sheet selection -->
                      <mat-label class="error" *ngIf="!valid['sheet_select']">Please select a sheet.</mat-label>
                      <mat-form-field appearance="fill">
                        <mat-label>Select the Sheet</mat-label>
                        <mat-select formControlName="sheet_select" (onclick)="this.valid['sheet_select'] = true">
                          <mat-option>Select...</mat-option>
                          <mat-option *ngFor="let sheet of sheetResults" [value]="sheet">
                            {{ sheet }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <button mat-raised-button type="button" class="btn btn-info" [routerLink]="'/home'">
                        Cancel
                      </button>
                      <button
                        mat-raised-button
                        type="submit"
                        class="btn btn-success float-right"
                        (click)="saveGoogleSource()"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </ng-container>
              </ng-container>

              <!-- END GOOGLE FORM-->
              <div class="clearfix"></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal modal-angular fade"
  id="googleAuthInfoModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="confirmModelLabel"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="border p-3 text-info rounded border-info">
          <div class="row g-0 p-3 justify-content-center">
            <h4 class="margin-top text-center">Opening Google Sign-in Screen</h4>

            <p class="text-center">
              This will open a new window where you can authorize VLOOKUP to search Google Drive and read your Google
              Sheets.
            </p>
            <p class="text-center">
              <button mat-raised-button class="btn btn-primary" (click)="googleAuthLink()">Continue</button>
            </p>
            <p class="text-center">
              Finished? If you completed the Google Authorization,
              <span (click)="removeGoogleAuthModal()" style="cursor: pointer; text-decoration: underline"
                >reload the VLOOKUP settings</span
              >.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
